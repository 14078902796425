<template>
	<view id="root">
		<view class="login-box">
			<view class="login-left">

				<view class="carousel-box">
					<el-carousel v-if="carouselList.length">
						<el-carousel-item :height="carouselHeight" v-for="(item, index) in carouselList"
							:key="index"><img :src="item.url" alt="" /></el-carousel-item>
					</el-carousel>
				</view>
			</view>
			<!-- <img style="position:fixed;top:13%;cursor:pointer;width:395px;z-index:999;left:20%;" src="../assets/img/login-kouhao.png" alt="" /> -->
			<div class="logo" style="position:fixed;top:13%;cursor:pointer;width:395px;z-index:999;left:20%;">
				<img class="img" style="width: 50px;margin-right: 20px;position: relative;top: 8px;"
					src="../assets/img/henyuan.png" alt="" />
				<span style="font-size: 36px;color: #000;font-weight: 600;font-family: cursive;">恒远通</span>
			</div>
			<view class="login-right">
				<view class="hnezGK">
					<view class="kjkerh">
						<view class="bBMinK">
							<div class="login_title">
								<span :class="isActiveIndex === 0 ? 'isActiveTitle' : ''"
									@click="accountLogin">账号登录</span>
								<span :class="isActiveIndex === 1 ? 'isActiveTitle' : ''" @click="smsLogin">扫码登录</span>
							</div>

							<el-form v-if="isActive" :model="param" :rules="rules" ref="login" label-width="0px"
								class="ms-content">
								<el-form-item prop="username">
									<el-input v-model="param.username" placeholder="用户名">
										<template #prepend>
											<el-button size="small" icon="el-icon-user" disabled
												style="cursor: context-menu;"></el-button>
										</template>
									</el-input>
								</el-form-item>
								<el-form-item prop="password">
									<el-input type="password" placeholder="密码" v-model="param.password"
										@keyup.enter="submitForm()">
										<template #prepend>
											<el-button size="small" icon="el-icon-lock" disabled
												style="cursor: context-menu;"></el-button>
										</template>
									</el-input>
								</el-form-item>
								<div class="eoLAua">
									<div>
										登录即表明您同意
										<a href="https://hengyuan.10000.work/hyt/yingshi.html     "
											target="_blank">《恒远通隐私声明》</a>
										及
										<a href="https://hengyuan.10000.work/hyt/fuwu.html"
											target="_blank">《恒远通用户协议》</a>
									</div>
								</div>
								<div class="login-btn"><el-button size="small" type="primary"
										@click="submitForm">登录</el-button></div>
							</el-form>

							<el-form v-else :model="param" :rules="rules" ref="login" label-width="0px"
								class="ms-content">
								<div v-if="isCode" class="v-0d8">
									<div class="ant-spin-nested-loading">
										<div class="ant-spin-container">
											<div class="_1UQFT">
												<div class="_3grbl" :style="mypagestyle" id="qrcode" ref="qrcode">
													<img v-if="qrcodeerweimashow" src="../assets/img/henyuan2.png"
														draggable="false" class="_3Z7di" alt="" />
												</div>
												<img v-if="qrcodeshow" src="../assets/img/zhidao.png" alt=""
													class="_1LuAn _2Akme" />
											</div>
											<img src="https://cdn.tungee.com/user/v1.8.11/650cd4d27883856f9cb9731b511cf31b.png"
												alt="" class="_2cVmK" @mouseenter="getqrcodeshow"
												@mouseout="outqrcodeshow" />
											<div class="_1jQx1">
												请使用
												<span style="color: rgb(17, 31, 44);">恒远通App</span>
												扫描二维码
											</div>
										</div>
									</div>
								</div>
							</el-form>
							<div class="kbTWYa">
								<div class="jGryIZ"
									style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;width: 372px;">
									<div class="iRDvLn" style="width: auto; justify-content: normal;">
										<!-- <button class="gXoFpm">
											iPhone
											<div class="bhXrfM">
												<img src="../assets/img/applet-erweima.jpg" class="ghrOLy" />
												<div font-size="12px" class="dZwbCj">扫码进入微信小程序</div>
											</div>
										</button> -->
										<button class="kpYSMU">
											Android
											<div class="bhXrfM">
												<img src="../assets/img/erweima.png" class="ghrOLy" />
												<div font-size="12px" class="dZwbCj">扫码下载Android客户端</div>
											</div>
										</button>
									</div>
									<!-- <a
										href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006084"
										target="_blank"
										rel="noopener noreferrer"
										class="sc-fjdhpX hYCxqF"
									>
										鄂公网安备 42018502006084号
									</a> -->
									<span
										style="display: inline-block;color: rgb(157, 165, 179);font-size: 14px;cursor: pointer;">
										<i class="el-icon-phone" style="font-size: 16px;"></i>
										咨询热线:
										<span style="font-weight: 600;font-size: 14px;">400-027-3598</span>
									</span>
								</div>
							</div>
						</view>
					</view>
				</view>
			</view>
		</view>
	</view>
</template>

<script>
	import qs from 'qs';
	import service from '../utils/request';
	import QRCode from 'qrcodejs2';
	import {
		loginSlideshowList,
	} from '../api/oem';
	export default {
		data() {
			return {
				param: {
					username: '',
					password: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				isActive: true,
				timer_interval: '',
				isActiveIndex: 0,
				isCode: true,
				headPortrait: '',
				account: '',
				logoimg2: false,
				difgAl: ['difgAl0', 'difgAl1', 'difgAl2', 'difgAl3'],
				carouselList: [],
				carouselHeight: document.documentElement.clientHeight + 'px',
				qrcodeshow: true,
				qrcodeerweimashow: false,
				mypagestyle: {
					transform: 'translateX(0px)'
				}
			};
		},
		created() {
			loginSlideshowList().then(res => {
				this.carouselList = res.data;
			});
			this.$store.commit('clearTags');
			this.carouselHeight = document.documentElement.clientHeight + 'px';


		},
		methods: {
			getqrcodeshow() {
				this.qrcodeshow = true;
			},
			outqrcodeshow() {
				this.qrcodeshow = false;
			},
			submitForm() {
				this.$refs.login.validate(valid => {
					if (valid) {
						this.getlogin();
					} else {
						this.$message.error('请输入账号和密码');
						return false;
					}
				});
			},
			getlogin() {
				const value = {
					account: this.param.username,
					password: this.param.password
				};
				service
					.post('/api/login/auth', qs.stringify(value))
					.then(res => {
						if (res.code === 200) {
							localStorage.setItem('ms_username', res.data.account);
							localStorage.setItem('ms_role', res.data.role);
							localStorage.setItem('token', 'Bearer ' + res.data.token);
							localStorage.setItem('lastLoginIp', res.data.lastLoginIp);
							localStorage.setItem('lastLoginAt', res.data.lastLoginAt);
							localStorage.setItem('user', res.data.user);
							localStorage.setItem('setSeconds', res.data.intervals);
							localStorage.setItem('company', res.data.company);
							localStorage.setItem('userId', JSON.stringify(res.data.userId));
							localStorage.setItem('companyId', JSON.stringify(res.data.companyId));
							localStorage.setItem('protectionLevel', JSON.stringify(res.data.protectionLevel));
							this.$message.success('登录成功');
							this.$router.push('/');
						} else {
							this.$message.error(res.message);
						}
					})
					.catch(error => {
						console.log(error);
						this.errored = true;
					});
			},

			accountLogin() {
				// 账号登录
				this.isActive = true;
				this.isActiveIndex = 0;
				clearInterval(this.timer_interval);
				this.logoimg2 = false;
				this.qrcodeshow = true;
				this.qrcodeerweimashow = false;
			},
			smsLogin() {
				// 二维码
				this.isActive = false;
				this.isActiveIndex = 1;
				this.isCode = true;
				setTimeout(() => {
					this.qrcodeshow = false;
				}, 5000);
				if (this.logoimg2 == false) {
					this.getQrLogin();
				}
			},
			getQrLogin() {
				service
					.get('/api/login/QrLogin')
					.then(res => {
						if (res.code === 200) {
							this.qrcodeHtml = res.data;
							this.timer_interval = setInterval(() => {
								this.queryStatus();
							}, 1000);
							this.GenerateQRCode();
						} else {
							this.$message.error(res.message);
						}
					})
					.catch(error => {
						this.errored = true;
					});
			},
			// 生成二维码
			GenerateQRCode() {
				const qrcode = new QRCode('qrcode', {
					width: 200, // 二维码宽度，单位像素
					height: 200, // 二维码高度，单位像素
					colorDark: '#000000',
					colorLight: '#ffffff',
					text: this.qrcodeHtml // 生成二维码的链接
				});
				this.qrcodeerweimashow = true;
				this.logoimg2 = true;
			},
			//查询扫码登录状态   invalid (二维码是否过期false==未过期) ScanCode(false==未扫码)
			queryStatus() {
				let data = {
					l: this.qrcodeHtml
				};
				service
					.get('/api/login/queryStatus?l=' + this.qrcodeHtml)
					.then(res => {
						if (res.code === 200) {
							console.log(res, 'headPortrait');
							if (res.data.invalid == false) {
								//是否过期
								if (res.data.ScanCode == true) {
									if (res.data.login == false) {
										this.isCode = false;
										this.account = res.data.account;
										this.headPortrait = res.data.headPortrait;
									} else {
										this.param.username = res.data.account;
										this.param.password = res.data.password;
										clearInterval(this.timer_interval);
										this.getlogin();
									}
								}
							} else {
								this.$message.error('二维码超时');
								clearInterval(this.timer_interval);
								this.accountLogin();
							}
						} else {
							this.$message.error(res.message);
						}
					})
					.catch(error => {
						this.errored = true;
					});
			}
		}
	};
</script>

<style scoped>
	#root {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
	}

	.login-box {
		background: #fff;
		height: 100%;

		overflow: hidden;
		min-height: 670px;
		display: flex;
		flex-direction: row;
		position: relative;
	}

	.login-left {
		position: relative;

		width: 100%;
		height: 100%;
	}

	/deep/ .el-carousel {
		height: 100% !important;
	}

	/deep/ .el-carousel img {
		width: 100%;
		height: 100%;
	}

	.login-right {
		position: fixed;
		width: 550px;
		height: 670px;
		padding-bottom: 52px;
		box-sizing: border-box;
		right: 10%;
		top: 50%;
		transform: translateY(-50%);
		background: #fff;
		box-shadow: 0 4px 20px 0 rgb(0 54 128 / 8%);
		z-index: 99;
	}

	.hnezGK {
		height: 100%;
		min-height: 598px;
	}

	.kjkerh {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		width: 70%;
		height: 100%;
		max-width: 450px;
		min-width: 288px;
		min-height: 575px;
		margin: 0px auto;
		padding: 0px;
	}

	.bBMinK {
		width: 100%;
		height: auto;
		margin-bottom: 0px;
	}

	.eoLAua {
		position: relative;
		display: flex;
		justify-content: space-between;
	}

	.eoLAua div {
		display: flex;
		font-size: 12px;
		line-height: 22px;
		color: rgb(157, 165, 179);
	}

	.eoLAua div a {
		color: rgb(95, 122, 184);
		text-decoration: none;
		font-size: 12px;
		cursor: pointer;
	}

	/deep/.login-left .el-carousel .el-carousel__container {
		height: 100% !important;
	}

	.ms-content {
		margin-top: 60px;
		width: 100%;
		height: 400px;
	}

	/deep/.ms-content .el-form-item {
		margin-bottom: 36px;
	}

	.bBMinK .login_title {
		text-align: center;
		margin: 0px 20px 25px 20px;
		color: rgba(17, 31, 44, 0.6);
		letter-spacing: 0;
		cursor: pointer;
	}

	.login_title span {
		margin: 20px;
		font-size: 24px;

		cursor: pointer;
	}

	.login_title span:hover {
		color: #333;
		font-weight: bolder;
		font-weight: 600;
	}

	.isActiveTitle {
		color: #333;
		font-weight: 600;
		padding-bottom: 10px;
		border-bottom: 2px solid #5d84fd;
	}

	.login-btn {
		text-align: center;
		margin-top: 36px;
	}

	.login-btn button {
		width: 100%;
		height: 45px;
		margin-bottom: 10px;
		font-size: 20px;
		border-radius: 0;
	}

	.login-tips {
		font-size: 12px;
		line-height: 30px;
		color: #fff;
	}

	/deep/.el-input__inner {
		height: 50px;
		line-break: 50px;
		border-radius: 0;
	}

	/deep/.el-input-group__prepend {
		border-radius: 0;
	}

	.ms-right {
		float: left;
		width: 450px;
		box-sizing: border-box;
	}

	.kbTWYa {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-width: 450px;
		position: absolute;
		bottom: 40px;
	}

	.jGryIZ {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
	}

	.hYCxqF {
		display: block;
		margin-top: 9px;
		padding-left: 25px;
		font-size: 12px;
		color: rgb(157, 165, 179);
		position: relative;
		right: -40px;
	}

	.hYCxqF::before {
		content: url(//aucrs.ingageapp.com/login/static/media/filing.d0289dc0.png);
		position: absolute;
		left: 0px;
		top: -1px;
	}

	.bhXrfM {
		width: 176px;
		padding: 10px 0px;
		background: rgb(255, 255, 255);
		display: none;
		z-index: 999;
	}

	.ghrOLy {
		height: 152px;
		width: 152px;
		margin: 0px auto;
		display: block;
	}

	.dZwbCj {
		font-size: 12px;
		color: rgba(25, 31, 37, 0.72);
		text-align: center;
	}

	.gXoFpm:hover .bhXrfM {
		display: block;
		position: absolute;
		top: -205px;
		left: -45px;
		border: 1px solid #ccc;
		animation-name: xianshi;
		animation-duration: 1s;
	}

	@keyframes xianshi {
		0% {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.gXoFpm {
		display: block;
		width: 78px;
		height: 32px;
		margin-right: 12px;
		padding-left: 29px;
		border: none;
		border-radius: 16px;
		font-size: 12px;
		color: rgb(157, 165, 179);
		line-height: 16px;
		background-color: rgb(248, 249, 252);
		position: relative;
		cursor: pointer;
	}

	.gXoFpm::before {
		content: url(//aucrs.ingageapp.com/login/static/media/IOS.8a7ae521.svg);
		position: absolute;
		top: 6px;
		left: 8px;
	}

	.kpYSMU {
		display: block;
		width: 78px;
		height: 32px;
		margin-right: 12px;
		padding-left: 29px;
		border: none;
		border-radius: 16px;
		font-size: 14px;
		color: rgb(157, 165, 179);
		line-height: 16px;
		background-color: rgb(248, 249, 252);
		position: relative;
		cursor: pointer;
	}

	.kpYSMU::before {
		content: url(//aucrs.ingageapp.com/login/static/media/Android.039cc413.svg);
		position: absolute;
		top: 6px;
		left: 8px;
	}

	.kpYSMU:hover .bhXrfM {
		display: block;
		position: absolute;
		top: -205px;
		left: -45px;
		border: 1px solid #ccc;
		animation-name: xianshi;
		animation-duration: 1s;
	}

	.iRDvLn {
		display: flex;
	}

	.v-0d8 {
		text-align: center;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		flex-direction: column;
		user-select: none;
		padding-top: 20px;
	}

	.ant-spin-nested-loading {
		position: relative;
	}

	.ant-spin-container {
		position: relative;
		transition: opacity 0.3s;
	}

	.v-0d8 ._1UQFT {
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		height: 300px;
	}

	.v-0d8 ._2cVmK {
		margin-top: 12px;
		width: 32px;
		cursor: pointer;
	}

	.v-0d8 ._1jQx1 {
		font-size: 14px;
		color: rgba(17, 31, 44, 0.6);
		letter-spacing: 0;
		text-align: center;
		line-height: 20px;
		margin-top: 24px;
		cursor: pointer;
	}

	.ant-spin-container:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		display: none\9;
		width: 100%;
		height: 100%;
		background: #fff;
		opacity: 0;
		transition: all 0.3s;
		content: '';
		pointer-events: none;
	}

	.v-0d8 ._3grbl {
		position: relative;
		height: 200px;
		width: 200px;
		z-index: 9;
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.v-0d8 ._1UQFT ._1LuAn {
		width: 220px;
		z-index: 1;
		margin-right: -30px;
	}

	.v-0d8 ._1UQFT ._1LuAn._2Akme {
		animation: IJw-y 3s 1 forwards;
	}

	.v-0d8 ._3grbl .WLAvM {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	.v-0d8 ._3grbl ._3Z7di {
		position: absolute;
		width: 52px;
		height: 52px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.v-0d8 ._3grbl .xWnCM {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: hsla(0, 0%, 100%, 0.9);
		cursor: pointer;
	}

	.v-0d8 ._3grbl .xWnCM ._3eFxE {
		margin-top: 76px;
		width: 48px;
		height: 48px;
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.v-0d8 ._3grbl .xWnCM .lAlbv {
		margin-top: 12px;
		font-size: 14px;
		color: #08f;
		letter-spacing: 0;
		text-align: center;
	}

	.v-0d8 ._2cVmK {
		margin-top: 12px;
		width: 32px;
		cursor: pointer;
	}

	.v-0d8 ._1jQx1 {
		font-size: 14px;
		color: rgba(17, 31, 44, 0.6);
		letter-spacing: 0;
		text-align: center;
		line-height: 20px;
		margin-top: 24px;
		cursor: pointer;
	}
</style>